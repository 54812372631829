// WebKit-style focus
@mixin tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.
@mixin img-responsive($display: block) {
  display: $display;
  width: 100% \9; // Force IE10 and below to size SVG images correctly
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@use "sass:color";
@use "mixins/clearfix";
@use "mixins/svgtools";
@use "variables";

//
// Footer
// --------------------------------------------------

// Basics
$front-logo-size:					300px;
$front-logo-height:					270px;
$front-logo-size-small:				150px;
$front-icon-size:					24px;
$front-icon-size-small:				36px;

$front-blur-radius:					5px;

$front-padding:						12px;

$front-lines:						color.adjust(variables.$theme-primary-dark, $lightness: -(variables.$theme-border-darker-percentage));

$front-theme-text:					variables.$theme-secondary-light;
$front-theme-hover-link:			$front-lines;
$front-theme-hover-background:		variables.$theme-secondary-light;
$front-theme-hover-line:			variables.$theme-secondary-light;

$front-menu-padding: 				5px;
$front-menu-margin: 				.2rem;


body.frontmatter {
	overflow: hidden;
	background: variables.$theme-primary-dark;
}

.front-wrapper {
	width: 100vw;
	@media (min-width: variables.$screen-sm-min) {
		background: variables.$theme-primary-dark url(../img/bkg.svg) repeat;
		height: 100vh;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}

.front {
	position: relative;
	@include clearfix.clearfix();
	
	h1, h2, h3, h4, h5, h6 {
		color: $front-theme-text;
		margin: 0;
		padding: 0;
	}
	
	@media (max-width: variables.$screen-xs-max) {
		text-align: center;
	}
}

.front-text > * {
	margin-bottom: variables.$line-height-computed;
}
.front-title, .front-text, .front-nav, .front-connect {
	text-align: center;
}

@media (min-width: variables.$screen-sm-min) {
	.blur-fill {
		z-index: 0;
		overflow: hidden;
	}

	.blur-fill::after {
		content: '';
		background: inherit; 
		position: absolute;
		left: -$front-blur-radius;
		right: -$front-blur-radius;
		top: -$front-blur-radius;
		bottom: -$front-blur-radius;
		z-index: -100;
		box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05);
		filter: blur($front-blur-radius);
	}

	.front {
		background: inherit;
		margin: 0 auto;
		width: $front-logo-size + 2*$front-padding;
		padding: $front-padding;
		box-shadow: 0 0 10px #000000ff;
	}

	.front-bottom {
		background: inherit;
		width: 100%;
		position: absolute;
		bottom: 0;
		width: $front-logo-size + 2*$front-padding;
	}

	.front-nav {
		margin-top: 0;
		margin-bottom: 0;
	}

	.front-nav,
	.front-connect {
		li {
			padding: 0;
			margin-right: $front-menu-margin;
		}

		a {
			display: block;
			color: $front-theme-text;
			padding: $front-menu-padding;
			border: thin solid transparent;
		}
	}

	.front-nav a:hover {
		color: $front-theme-hover-link;
		background: $front-theme-hover-background;
		border: thin solid $front-theme-hover-line;
		text-decoration: none;
	}
}

// This is the site logo
.front-logo {
	@include svgtools.svg-icon-sz($front-logo-size-small);

	@media (min-width: variables.$screen-sm-min) {
		@include svgtools.svg-icon-sz($front-logo-size);
		height: $front-logo-height;
	}
}

// This is for the connect icons
.front-icon {
	@include svgtools.svg-icon-sz($front-icon-size-small);
	
	@media (min-width: variables.$screen-sm-min) {
		@include svgtools.svg-icon-sz($front-icon-size);
	}
}
@use "mixins/svgtools";

//
// SVG Icon Import
// --------------------------------------------------

.icon-logo {
	@include svgtools.import-svg("icon-logo");
}

.icon-linkedin {
	@include svgtools.import-svg("icon-linkedin");
}

.icon-github {
	@include svgtools.import-svg("icon-github");
}

.icon-mail {
	@include svgtools.import-svg("icon-mail");
}

.icon-rss {
	@include svgtools.import-svg("icon-rss");
}
@use "mixins/generic";
@use "variables";

//
// Scaffolding
// --------------------------------------------------


// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}


// Body reset

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: variables.$font-family-base;
  font-size: variables.$font-size-base;
  line-height: variables.$line-height-base;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}


// Links

a {
  color: variables.$link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: variables.$link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    @include generic.tab-focus();
  }
}


// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
  margin: 0;
}


// Images

img {
  vertical-align: middle;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
  @include generic.img-responsive();
}

// Rounded corners
.img-rounded {
  border-radius: variables.$border-radius-large;
}

// Horizontal rules

hr {
  margin-top:    variables.$line-height-computed;
  margin-bottom: variables.$line-height-computed;
  border: 0;
  border-top: 1px solid variables.$hr-border;
}

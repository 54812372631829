@use "sass:math";
@use "sass:color" as color;

//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-darker: color.adjust(#000, $lightness: 13.5%); // #222
$gray-dark: color.adjust(#000, $lightness: 20%); // #333
$gray: color.adjust(#000, $lightness: 33.5%); // #555
$gray-light: color.adjust(#000, $lightness: 46.7%); // #777
$gray-lighter: color.adjust(#000, $lightness: 93.5%); // #eee

$theme-neutral: #FFF;
$theme-background: #FFF;
$theme-border: color.adjust(#542437, $lightness: 20%);
$theme-primary-dark: #542437;
$theme-primary-lessdark: color.adjust($theme-primary-dark, $lightness: 10%);
$theme-primary-light: #baa7af; // #987b87;
$theme-secondary-light: $theme-neutral;
$theme-secondary-dark: #99B2B7;
$theme-border-darker-percentage: 6.5%;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Global textual link color.
$link-color: $theme-primary-dark;
$link-hover-color: color.adjust($link-color, $lightness: -15%);


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
$font-family-serif: "Rokkitt", Georgia, Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: "Droid Sans Mono", Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 18px;
$font-size-large: math.ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: math.ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1: math.floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: math.floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: math.ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: math.ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: math.ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: math.floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: $font-family-serif;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: $theme-primary-dark;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$line-height-large: 1.33;
$line-height-small: 1.5;

$border-radius-base: 4px;
$border-radius-large: 6px;
$border-radius-small: 3px;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1010;
$zindex-footer: 1000;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min: 480px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: (
    $screen-sm-min - 1
);
$screen-sm-max: (
    $screen-md-min - 1
);
$screen-md-max: (
    $screen-lg-min - 1
);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: (
    $grid-float-breakpoint - 1
);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (
    (720px + $grid-gutter-width)
);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (
    (940px + $grid-gutter-width)
);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (
    (1140px + $grid-gutter-width)
);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;



//== Code
//
//##

$code-color: #c7254e;
$code-bg: #fdf6e3; // #f9f2f4;

$kbd-color: #fff;
$kbd-bg: #333;

$pre-bg: #fdf6e3;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $gray-light;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Headings small color
$headings-small-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: (
    $font-size-base * 1.25
);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
//** Page header border color
$page-header-border-color: $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
//** Horizontal line color.
$hr-border: $gray-lighter;
//** Highlighted text color.
$text-highlight: $gray-lighter;
// SVG Image
@mixin svg-icon-sz($sz) {
	@include svg-icon-size($sz, $sz);
}
@mixin svg-icon-size($wd, $ht) {
  display: inline-block;
  width: $wd;
  height: $ht;
  background-size: $wd $ht;
	background-repeat: no-repeat;
}

@mixin import-svg($img) {
	background-image: url("/img/icon/#{$img}.svg");
}
@use "sass:color";
@use "sass:math";
@use "mixins/clearfix";
@use "mixins/generic";
@use "mixins/svgtools";
@use "variables";

//
// Navbars
// --------------------------------------------------

// Color and scale information
//

// Basics of a navbar
$navbar-height: 50px;
$navbar-logo-size: 40px;
$navbar-margin-bottom: 0; // $line-height-computed;
$navbar-padding-horizontal: math.floor(calc(variables.$grid-gutter-width / 2));
$navbar-padding-vertical: calc(($navbar-height - variables.$line-height-computed) / 2);

$navbar-theme-bg: variables.$theme-primary-dark;
$navbar-theme-logo-color: variables.$theme-neutral;
$navbar-theme-border: color.adjust($navbar-theme-bg, $lightness: -(variables.$theme-border-darker-percentage));
$navbar-theme-link: variables.$theme-secondary-light;

// Hover
$navbar-theme-hover-link: variables.$theme-secondary-dark;
$navbar-theme-hover-line: variables.$theme-secondary-dark;

// Current
$navbar-theme-current-link: variables.$theme-neutral;
$navbar-theme-current-bg: variables.$theme-primary-lessdark;
$navbar-theme-current-line: color.adjust($navbar-theme-bg, $lightness: -(variables.$theme-border-darker-percentage)); //darken($navbar-theme-current-bg, 6.5%);


// Navbar vertical align
//
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.

@mixin navbar-vertical-align($element-height) {
	margin-top: calc(($navbar-height - $element-height) / 2);
	margin-bottom: calc(($navbar-height - $element-height) / 2);
}


// Wrapper
//
.navbar {
	position: relative;
	min-height: $navbar-height;
	margin-bottom: $navbar-margin-bottom;
	z-index: variables.$zindex-navbar;
	padding-left: $navbar-padding-horizontal;

	// Color and styling
	background-color: $navbar-theme-bg;
	border-bottom: thin solid $navbar-theme-border;
	color: $navbar-theme-link;

	// Prevent floats from breaking the navbar
	@include clearfix.clearfix();
}

// Navbar links area
.navbar-links {
	overflow-x: visible;
	padding: 0 $navbar-padding-horizontal;
	margin: 0;
	float: right;
	@include clearfix.clearfix();
	-webkit-overflow-scrolling: touch;

	>li {
		list-style-type: none;
		display: inline-block;
	}

	>li>a {
		display: block;
		height: $navbar-height;
		padding: $navbar-padding-vertical $navbar-padding-horizontal;
		color: $navbar-theme-link;

		&:hover,
		&:focus {
			border-bottom: medium solid $navbar-theme-hover-line;
			color: $navbar-theme-hover-link;
			text-decoration: none;
		}
	}

	.navbar-current a {
		color: $navbar-theme-current-link;
		border-left: thin solid $navbar-theme-current-line;
		border-right: thin solid $navbar-theme-current-line;
		background: $navbar-theme-current-bg;

		&:hover,
		&:focus {
			color: $navbar-theme-current-link;
			border-bottom: none;
			text-decoration: none;
		}
	}

	display: none;

	@media (min-width: variables.$grid-float-breakpoint) {
		width: auto;
		box-shadow: none;
		display: inherit;
	}
}

// Brand/project name
.navbar-brand {
	float: left;
	height: $navbar-height;
	color: $navbar-theme-logo-color;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $navbar-theme-logo-color;
	}

	@media (min-width: variables.$grid-float-breakpoint) {

		.navbar>.container &,
		.navbar>.container-fluid & {
			margin-left: -$navbar-padding-horizontal;
		}
	}

	>span {
		line-height: $navbar-logo-size;
		@include navbar-vertical-align($navbar-logo-size);
	}

	.navbar-logo {
		@include svgtools.svg-icon-sz($navbar-logo-size);
	}

	.navbar-logo+.navbar-logo-text {
		margin-left: $navbar-padding-horizontal;
	}

	.navbar-logo-text {
		display: inline-block;
	}
}

// Toggle the visibility of navbar-links
.navbar-toggle {
	display: block;
	$toggle-size: 36px;
	$toggle-padding: 4px;
	width: $toggle-size;
	height: $toggle-size;
	font-size: $toggle-size - $toggle-padding * 2;
	text-align: center;

	position: relative;
	float: right;
	margin-right: $navbar-padding-horizontal;
	@include navbar-vertical-align($toggle-size);

	color: $navbar-theme-link;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $navbar-theme-hover-link;
	}

	@media (min-width: variables.$grid-float-breakpoint) {
		display: none;
	}
}


// Touch Assist
$touch-assist: color.adjust($navbar-theme-bg, $lightness: -(variables.$theme-border-darker-percentage));
$touch-assist-current: color.adjust($navbar-theme-bg, $lightness: variables.$theme-border-darker-percentage);
$touch-assist-padding: 6px;

.sitemap {

	// If on small screen sizes, add more padding and make the hit-area obvious:
	@media (max-width: variables.$grid-float-breakpoint) {
		>li {
			min-width: 80%;

			&.navbar-current>a {
				background: $touch-assist-current;
				border: thin solid $touch-assist;
			}
		}

		>li>a {
			display: block;
			width: 100%;

			padding: $touch-assist-padding;
			margin: $touch-assist-padding auto;
			background: $touch-assist;
			border: thin solid $touch-assist;

			color: $navbar-theme-link;

			&:hover,
			&:focus {
				color: $navbar-theme-hover-link;
				text-decoration: none;
			}
		}
	}
}
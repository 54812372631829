@use "sass:color";
@use "mixins/clearfix";
@use "mixins/svgtools";
@use "navbar";
@use "variables";

//
// Footer
// --------------------------------------------------

// Basics
$footer-min-height:              200px;
$footer-logo-size:							 0.8 * $footer-min-height;
$footer-padding-top:        		 variables.$line-height-computed;
$footer-icon-size:							 36px;

$footer-theme-bg:                variables.$theme-primary-dark;
$footer-theme-border:            color.adjust($footer-theme-bg, $lightness: -(variables.$theme-border-darker-percentage));
$footer-theme-text:							 variables.$theme-secondary-light;
$footer-theme-link:              variables.$theme-neutral;

// Hover
$footer-theme-hover-link:        variables.$theme-secondary-dark;

// Current
$footer-theme-current-link:      variables.$theme-neutral;
$footer-theme-current-bg:      	 variables.$theme-primary-lessdark;

// Wrapper
//
.page-footer {
  position: relative;
	overflow: auto;
  min-height: $footer-min-height;
	z-index: variables.$zindex-footer;
	
	// Color and styling
	background-color: $footer-theme-bg;
	border-top: thin solid $footer-theme-border;
	color: $footer-theme-link;
    
  // Prevent floats from breaking the navbar
  @include clearfix.clearfix();
	
	h1, h2, h3, h4, h5, h6 {
		color: $footer-theme-text;
	}
	
	text-align: center;
	@media (min-width: variables.$grid-float-breakpoint) {
		text-align: left;
	}
}

// Remove padding when contained in a .container:
.container,
.container-fluid {
  > .navbar-brand,
  > .navbar-links {
    margin-right: -(navbar.$navbar-padding-horizontal);
    margin-left:  -(navbar.$navbar-padding-horizontal);
  }
}

// Footer links area
.page-footer-links {
  overflow-x: visible;
	padding: 0;
  @include clearfix.clearfix();
  -webkit-overflow-scrolling: touch;
	> li {
		list-style-type: none;

		> a {
			color: $footer-theme-link;
		}
	}
}

.footer-jump-top {
	@media (min-width: variables.$grid-float-breakpoint) {
		display: none;
	}
}

// Brand/project name
.page-footer-brand {
	width: 100%;
	text-align: center;
	
	.page-footer-logo {
		@include svgtools.svg-icon-sz(navbar.$navbar-logo-size);
		
		@media (min-width: variables.$grid-float-breakpoint) {
			@include svgtools.svg-icon-sz($footer-logo-size);
		}
	}
}

.footer-copyright {
	&:before {
		clear: both;
	}
	width: 100%;
	text-align: center;
	color: $footer-theme-text;
	
	a {
		color: $footer-theme-link;
		
		&:hover,
		&:focus {
			color: $footer-theme-hover-link;
			text-decoration: none;
		}
	}
}

.footer-icon {
	@include svgtools.svg-icon-sz($footer-icon-size);
}
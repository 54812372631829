@use "sass:color";
@use "mixins/clearfix";
@use "mixins/generic";
@use "variables";

@use "type.scss";

// Page background:
body {
	background: variables.$theme-primary-dark;
}

.container-bkg {
	width: 100%;
	overflow: hidden;

	@media (min-width: variables.$screen-sm-min) {
		background: variables.$theme-primary-light url(../img/bkg.svg) repeat;
	}
}

.main-content {
	// Force to grow to fit content
	overflow: auto;

	// Give more space to the top and bottom
	padding-top: variables.$font-size-h1;
	padding-bottom: variables.$font-size-h1;

	// Colors and themes:
	background: variables.$theme-background;
	border-left: thin solid variables.$theme-border;
	border-right: thin solid variables.$theme-border;
}

.main-text {
	line-height: 1.5;
	padding: 0 3em;

	@media (max-width: variables.$grid-float-breakpoint) {
		padding: 0 10px;
	}

	a {
		text-decoration: underline;
	}

	p {
		margin: 0 0 16px;
	}
}

p.blog-image.full-width {
	margin: -(variables.$font-size-h1) -3em variables.$font-size-h1;

	@media (max-width: variables.$grid-float-breakpoint) {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.page-leader p.blog-image:first-child {
	margin: -(variables.$font-size-h1) 0 variables.$font-size-h1;
}

.main-text p.blog-image:first-child {
	// Change the left/right margins to remove the space, and
	// shift the top margin to under the image.
	margin: -(variables.$font-size-h1) -3em variables.$font-size-h1;

	@media (max-width: variables.$grid-float-breakpoint) {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.main-text .postlink a {
	text-decoration: none;
}

.page-title {
	// Force to grow to fit content
	overflow: auto;
	width: 100%;

	// Colors and themes:
	background: variables.$theme-primary-lessdark;
	border-bottom: thin solid variables.$theme-primary-dark;

	h1 {
		color: variables.$theme-neutral;
	}

	h2 {
		color: variables.$theme-primary-light;
		font-size: variables.$font-size-h3;
	}

	padding-bottom: variables.$font-size-h3;

	@media (min-width: variables.$grid-float-breakpoint) {
		h1 {
			margin-top: variables.$font-size-h1;
		}

		h2 {
			margin-top: variables.$font-size-h3;
		}
	}

}

$scrollspy-padding: 10px;
$scrollspy-border-hover: 1px;
$scrollspy-border-current: 2px;

.sidebar-nav {
	.scrollspy-display {
		display: none;
	}

	&.fixed {
		position: fixed;
		top: 0;
	}

	ol,
	ul {
		@extend .list-unstyled;

		>li {
			padding-left: $scrollspy-padding;
			border-left: 0;

			a {
				color: variables.$theme-primary-dark;

				&:hover {
					text-decoration: none;
					cursor: pointer;
				}
			}

			&:hover {
				padding-left: $scrollspy-padding - $scrollspy-border-hover;
				border-left: $scrollspy-border-hover solid variables.$theme-primary-dark;
			}

			&.scrollspy-current,
			&.navbar-current {
				padding-left: $scrollspy-padding - $scrollspy-border-current;
				border-left: $scrollspy-border-current solid variables.$theme-primary-dark;
			}
		}
	}

	@media (max-width: variables.$screen-sm-max) {
		display: none;
		visibility: hidden;
	}
}

.page-footer>.container>.row>div {
	padding-bottom: variables.$line-height-computed;
}

$postdate-width: 42px;
$postdate-margin: 8px;

.postlink {
	@include clearfix.clearfix();
	overflow: auto;
	margin-bottom: variables.$line-height-computed;
	margin-left: calc(variables.$line-height-computed / 2);
	padding-left: $postdate-width + $postdate-margin;

	.postdate {
		padding: 3px;
		width: $postdate-width;
		height: 100%;
		float: left;

		text-transform: uppercase;
		text-align: center;
		background: variables.$theme-primary-lessdark;

		margin-left: -($postdate-width + $postdate-margin);

		* {
			margin: 0;
			padding: 0;
			color: variables.$theme-secondary-light;
		}

	}

	div {
		margin: 0;
	}
}

.list-menu {
	@extend .list-inline;
	width: 100%;

	>li {
		&:first-child {
			margin-left: auto;
		}

		&:last-child {
			margin-right: auto;
		}
	}
}

.blog-image {
	display: block;
	margin: auto;

	* {
		@include generic.img-responsive;
		margin: auto;
		text-align: center;
	}
}

table {
	margin: variables.$line-height-computed 0;
	width: 100%;

	thead {
		border-bottom: thin solid variables.$theme-primary-dark;
	}

	td {
		padding: 0.25em;
	}

	tr:nth-child(even) {
		background-color: color.adjust(variables.$theme-secondary-light, $lightness: -(variables.$theme-border-darker-percentage));
	}
}

.youtube-wrapper {
	text-align: center;
}

.youtube {
	border: none;
	max-width: 100%;
}

h3.current-year {
	border-top: thick solid variables.$theme-primary-light;
	padding-left: calc(variables.$line-height-computed / 2);
	margin-top: 2* variables.$line-height-computed;

	&:first-child {
		margin-top: variables.$line-height-computed;
	}
}
@use "variables";

//
// Code (inline and block)
// --------------------------------------------------


// Inline and block code styles
code,
kbd,
pre,
samp {
  font-family: variables.$font-family-monospace;
}

// Inline code
code {
  padding: 2px 4px;
  font-size: 90%;
  color: variables.$code-color;
  background-color: variables.$code-bg;
  border-radius: variables.$border-radius-base;
}

// User input typically entered via keyboard
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: variables.$kbd-color;
  background-color: variables.$kbd-bg;
  border-radius: variables.$border-radius-small;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);

  kbd {
    padding: 0;
    font-size: 100%;
    box-shadow: none;
  }
}

// Blocks of code
pre {
  display: block;
  padding: calc((variables.$line-height-computed - 1px) / 2);
  margin: 0 0 calc(variables.$line-height-computed / 2);
  font-size: (variables.$font-size-base - 1); // 14px to 13px
  line-height: variables.$line-height-base;
  word-break: break-all;
  word-wrap: break-word;
  color: variables.$pre-color;
  background-color: variables.$pre-bg;
  border: 1px solid variables.$pre-border-color;
  border-radius: variables.$border-radius-base;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: variables.$pre-scrollable-max-height;
  overflow-y: scroll;
}
